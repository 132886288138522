import React, { ReactElement } from "react";
import MainBanner from "@components/crmoffers/mainBanner";
import CrmOffersWrapper from "@components/layouts/crmOffersWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import OffersList from "@components/shared/offersList";

const WhyUs = dynamic(() => import("@components/crmoffers/whyUs"));

export default function CompareCRMPricesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <CrmOffersWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
        </CrmOffersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
